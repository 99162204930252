import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

// Cookie Consent
const cookieConsent = (function(){

	//states
  let loaded = false;

	//cache DOM
  const $el = $('#cookies');
  
	//bind events
  function bindEvents(){
    $el.on('click', show);
  }

	function init(){
    if (loaded) return;
    loaded = true;
    bindEvents()
    CookieConsent.run({
      onFirstConsent: function () {
        console.log('onFirstAction fired');
      },
      onConsent: function ({ cookie }) {
        console.log('onConsent fired ...', cookie);
        // if(CookieConsent.acceptedCategory('analytics')){
        //   // Analytics category enabled
        //   enableGoogleAnalytics();
        // } else {
        //   disableGoogleAnalytics();
        // }
      },
      onChange: function({changedCategories, changedServices}){
        console.log('onChance fired ...', changedCategories);
        // if(changedCategories.includes('analytics')){
        //   if(CookieConsent.acceptedCategory('analytics')){
        //     // Enable Google Analytics tracking after a certain event, for example, after consent
        //     enableGoogleAnalytics();
        //   }else{
        //     // Disable Google Analytics tracking
        //     disableGoogleAnalytics();
        //   }
        // }
      },
      categories: {
        necessary: {
          readOnly: true,
          enabled: true,
        },
        analytics: {
          autoClear: {
            cookies: [
              {
                name: /^(_ga|_gid)/,
              },
            ],
          },
        },
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: "Cookie Consent",
              description:
                'We use cookies to understand how our website is used and enhance your browsing experience. For more details about cookies and other data, please read the full <a href="/privacy-policy" class="cc__link">privacy policy</a>.',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage preferences',
              //closeIconLabel: 'Close',
            },
            preferencesModal: {
              title: 'Cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Save preferences',
              closeIconLabel: 'Close',
              sections: [
                {
                  title: 'Cookie Usage',
                  description:
                    'We use cookies to understand how our website is used and enhance your browsing experience. You can choose to opt-in/out whenever you want. For more details about cookies and other data, please read the full <a href="/privacy-policy" class="cc__link">privacy policy</a>.',
                },
                {
                  title: 'Strictly necessary cookies',
                  description: 'These cookies are necessary for the basic functionality of our website and cannot be disabled.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Performance and Analytics cookies',
                  linkedCategory: 'analytics',
                  cookieTable: {
                    headers: {
                      name: 'Name',
                      domain: 'Service',
                      description: 'Description',
                      expiration: 'Expiration',
                    },
                    body: [
                      {
                        name: '_ga',
                        domain: 'Google Analytics',
                        description:
                          'We use analytics cookies to gather information about how our website is used. This helps us improve and customize your experience. Cookie set by <a href="https://analytics.withgoogle.com/" target="_blank">Google Analytics</a>.',
                        expiration: '1 year',
                      }
                    ],
                  },
                },
                {
                  title: 'More information',
                  description:
                    'For any questions related to cookies and your choices, please <a class="cc__link" href="https://docs.google.com/forms/d/e/1FAIpQLSc5ncOZnaSTm0hyubeeeJRw2bxA0GhDdDmNTtT2lIBiG2tdgw/viewform">contact us</a>.',
                },
              ],
            },
          },
        },
      }
    });
	}

  function show(){
    CookieConsent.showPreferences();
  }

  // Note: using script tags instead
  function disableGoogleAnalytics() {
    // Disable Google Analytics tracking
    window['ga-disable-G-JPXPSM61JQ'] = true;
    gtag('consent', 'update', {
      'analytics_storage': 'denied',
      'wait_for_update': 500
    });
  }

  function enableGoogleAnalytics() {
    // Re-enable Google Analytics tracking
    window['ga-disable-G-JPXPSM61JQ'] = false;
    gtag('consent', 'update', {
      'analytics_storage': 'granted',
      'wait_for_update': 500
    });
  }

	return {
		init: init
	};

})(); //cookieConsent

export {cookieConsent};