// CSS
import './assets/css/shared/close-button.css';
import './assets/css/shared/icons.css';
import './assets/css/shared/key.css';
import './assets/css/shared/trademark.css';
import './assets/css/tailwind-style.css';

// JS
import './assets/js/shared/refreshOnBackButton.js';
import {cookieConsent} from './assets/js/shared/cookieConsent.js';
cookieConsent.init()

// Plugins
import "@babel/polyfill"; // For async functions